import React, { useState } from 'react';
import tw from 'twin.macro';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Container, ContentWithPadding } from '@components/Layout';
import { SectionHeading } from '@components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from '@components/styled/Button';
import Img from 'gatsby-image';
import StarIconSrc from '@assets/images/app/star.png';
import { IBusiness } from '@models';
import { FaStar } from 'react-icons/fa';
import { navigate } from 'gatsby';



interface BusinessProps {
  business:IBusiness;
}

const CardContainer = tw.div`border border-gray-100 bg-white rounded p-4 flex flex-col justify-start leading-normal shadow hocus:border-primary-light-500 hocus:cursor-pointer`;
const CardBody = tw.div`mb-2 flex-grow`;
const CardBodyTitle = tw.div`text-primarydark font-bold text-base lg:text-lg mt-2 mb-1 `;
const CardBodyClaim = tw.div`text-gray-700 text-base flex-grow h-full`;
const CardHeader = tw.div`flex items-center flex items-start justify-between`;
const CardHeaderBusinessAvatar = tw.img`h-12 w-12 rounded-full object-cover shadow-md`;
const CardViewButtonContainer = tw.div`self-end w-full flex items-center justify-end flex-col`;
const CardViewButton = tw(PrimaryButtonBase)`text-sm bg-green-500 px-4 py-2 text-white rounded-sm`;
const LikeButton = tw.button`rounded-full bg-yellow-100 p-1`
const LikeIcon = tw(FaStar)`text-yellow-500`

/*
<div class="max-w-sm w-full lg:max-w-full lg:flex">
  <div class="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" style="background-image: url('/img/card-left.jpg')" title="Woman holding a mug">
  </div>
  <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
    <div class="mb-8">
      <p class="text-sm text-gray-600 flex items-center">
        <svg class="fill-current text-gray-500 w-3 h-3 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
        </svg>
        Members only
      </p>
      <div class="text-gray-900 font-bold text-xl mb-2">Can coffee make you a better developer?</div>
      <p class="text-gray-700 text-base">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.</p>
    </div>

    <!-- cabecera con imagen de avatar -->
    <div class="flex items-center">
      <img class="w-10 h-10 rounded-full mr-4" src="/img/jonathan.jpg" alt="Avatar of Jonathan Reinink">
      <div class="text-sm">
        <p class="text-gray-900 leading-none">Jonathan Reinink</p>
        <p class="text-gray-600">Aug 18</p>
      </div>
    </div>
  </div>
</div>


<!-- version bootstrap -->
<div className="card" style={{width: '18rem'}}>
  <img src={business.image.url} className="card-img-top" alt="{business.name}"/>
  <div className="card-body">
    <h5 className="card-title">{business.name}</h5>
    <p className="card-text">{business.claim}</p>
    <a href="#" className="btn btn-primary">ver</a>
  </div>
</div>
*/
const BusinessListCardComponent = ({business}:BusinessProps ) => {
  /*
   * Card component to render a business element on list
   * sample https://tailwindcss.com/components/cards/
   * design inspiration https://dribbble.com/shots/7634389-Marketplace-design-for-FinTech-startup/attachments/396338?mode=media
   */
  console.log('MenuCaBusinessListCardComponentrdComponent - business ',business);
  const buttonView = false;
  
  return (
    <CardContainer onClick={() => {navigate("/establecimiento/"+business.slug);}}>
        <CardHeader>
            <CardHeaderBusinessAvatar
                alt={business.name}
                src={business.image?.url}
            />
            <LikeButton>
            <LikeIcon/>
            </LikeButton>
        </CardHeader>

        <CardBody>
            <CardBodyTitle>{business.name}</CardBodyTitle>
            <CardBodyClaim className="claimText">{business.claim}</CardBodyClaim>
        </CardBody>

        { buttonView &&
        <CardViewButtonContainer>
            <CardViewButton>Ver</CardViewButton>
        </CardViewButtonContainer> 
        }
    </CardContainer>
  );
};

export default BusinessListCardComponent;