import React, { useState, useEffect, useCallback, useRef } from 'react';
import tw from 'twin.macro';
import { Link } from 'gatsby';
import { useData, useLoading } from '@data-provider/react';
import { motion } from 'framer-motion';
import { FaChevronDown } from 'react-icons/fa';
import { usePaginatedQuery, queryCache, useInfiniteQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

import LayoutContent from '@components/Layout';
import Header from '@components/Header';
import SEO from '@components/Seo';
import MiniFooter from '@components/Footer/MiniFooter';
import CookieBanner from '@components/Cookies/CookieBanner';

// import { getBusinessPage } from '@services/business.service';
import BusinessAPI from '@services/api-query.service';
import { IBusiness, IAPIResponse, IPagination } from '@models';

import BusinessListCardComponent from '@components/business/BusinessListCardComponent';
import PaginationListComponent from '@components/pagination/PaginationListComponent';
import { IPageParams } from '@models/pagination.model';
import { useGetBusinessPage, useGetBusinessInfinite, getBusinessPage } from '@services/business.service';
import { CircularProgress } from '@material-ui/core';
import imgHero from "@assets/images/app/fill.svg";
import styled from 'styled-components';
/*

cocinaparati
https://tailwindcomponents.com/component/e-commerce-product-listing-page
*/

const SectionContainer= tw.section`p-4 pt-4 md:pt-24 block flex justify-center flex-wrap flex-row items-stretch w-full`;
const ArticlesContainer= tw.div`sm:w-full max-w-6xl mx-auto pt-20 sm:pt-0 md:pt-10 z-0`;
const CardContainerList = tw(motion.div)`mt-16 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 md:gap-3`;
const CardContainer = tw.div`mt-2 md:mt-10 w-1/2 md:w-1/3 lg:w-1/4 sm:pr-2 md:pr-6 lg:pr-12`;
const PageContainer = tw.div`mt-2 flex flex-row items-end justify-end text-xl font-bold text-primarydark`
const MoreIcon = tw(FaChevronDown)`w-12 h-12 text-primary-900`;

const SectionContainerStyled = styled(SectionContainer)`
        // padding-top: 100px;
        background: url(${imgHero}) no-repeat;
        background-size: 100% 150%;
        background-position: 45vw;
        // padding-left: 100px;
        `;  
        
const BusinessList : React.FC<any> = props => { // ({location, pageContext})

    
    const [pageNumber, setPageNumber] = useState(0);

    const [businessList, setBusinessList] = useState<Array<IBusiness>>(new Array());
    const [pagination, setPagination] = useState<IPagination>(null);
    const debug = false;

    //var businessList: Array<IBusiness> = new Array();
    //var pagination: IPagination;

    /*
    useEffect(() => {
        console.log('useEffect',pageNumber);
        businessPage();
    }, [pageNumber]);
    */
    
    


    //console.log('retrieved business list', responseData?.data?.content);
    const onNextPage = () => {
        console.log('onNextPage');
        setPageNumber(pageNumber + 1);
        // businessPage();
    }

    const onPrevPage = () => {
        console.log('onPrevPage');
        setPageNumber(pageNumber - 1);
        
        // businessPage();
    }
/*
    const businessPage = () => {
        const pageParams: IPageParams = {
            pageNumber: pageNumber,
            pageSize: 20
        };

        const businessProvider = getBusinessPage(pageParams);
        // const response: IAPIResponse<IBusiness> = useData(businessProvider);
        const response = useData(businessProvider);
        console.log('businessPage --- useData ', response);
        const responseData: IAPIResponse<IPagination<IBusiness>> = response?.data;
        businessList.push(responseData.data.content);
        pagination = responseData.data;
    }


    const pageParams: IPageParams = {
        pageNumber: pageNumber,
        pageSize: 20
    };
    const businessProvider = getBusinessPage(pageParams);
    // const response: IAPIResponse<IBusiness> = useData(businessProvider);
    const response = useData(businessProvider);
    console.log('businessPage --- useData ', response);
    // businessPage();
    const responseData: IAPIResponse<IPagination<IBusiness>> = response?.data;
    businessList.push(responseData.data.content);
    pagination = responseData.data;
*/



/*
    const fetchBusiness = useCallback(async (key, page = 0) => {
        const pageParams: IPageParams = {
            pageNumber: page,
            pageSize: 20
        };

        //const data: IAPIResponse<IPagination<IBusiness>> = await BusinessAPI.get(`recent?page=${pageParams.pageNumber}&size=${pageParams.pageSize}`);
        const { data } = await BusinessAPI.get(`recent?pageNumber=${pageParams.pageNumber}&page=${pageParams.size}`);

        return data;
    }, []);

    const {
        status,
        resolvedData,
        latestData,
        error,
        isFetching,
    } = usePaginatedQuery(['businessPage', pageNumber], fetchBusiness, {})
*/
    const {
        status,
        data,
        error,
        isFetching,
        isFetchingMore,
        fetchMore,
        canFetchMore,
    } = useInfiniteQuery(['businessInfinite', pageNumber], getBusinessPage, {
        getFetchMore: (lastGroup, allGroups) => {console.log('getFetchMore list ' + pageNumber, allGroups); return lastGroup.last?undefined:1;}
    });
    
    const loadMoreRef = useRef();
/*
    useEffect(() => {
*
        if (latestData){
            


            addPage(latestData);
            /*
            if (!latestData?.data?.last) {
                queryCache.prefetchQuery(['businessPage', pageNumber + 1], fetchBusiness);
                // setPageNumber(1);
            }
            *
                    
        }
*
    }, [latestData, pageNumber]);
    */

    const addPage = (pageInfo: IAPIResponse<IPagination<IBusiness>>) => {
        console.log('addPage --- page -- ', pageInfo.data.number);
        /*
        //businessList = businessList.concat(pageInfo?.data.content);
        pageInfo?.data.content.map((business: IBusiness) => {
            businessList.push(business);
        });
        */

        setBusinessList(businessList.concat(pageInfo?.data.content));
        setPagination(pageInfo?.data);
        console.log('addPage --- businessList', businessList);
        console.log('addPage --- pagination', pagination);

        console.log('addPage -- ')
    }

    return (
        <LayoutContent>
            <Header />
            <SEO
                lang="es" title="Cocina para ti" keywords={[`solidaridad`, `barrio`, `madrid`, `restaurantes`, `para llevar`, `banco de alimentos`]} 
            />       
            <SectionContainerStyled id="business-list">
            
            {status === 'loading' ? (
                <CircularProgress color="primary" size="5rem" thickness={2}/>
            ) : status === 'error' ? (
                <span>Ups</span>
            ) : (
            <>
                <CardContainerList className="mx-auto">
                {data?.map((page, i) => (
                <React.Fragment key={i}>
                {
                
                page.content?.map((business: IBusiness, index: number) => (
                    <BusinessListCardComponent business={business} key={business.token}></BusinessListCardComponent>
                ))}
                </React.Fragment>
                ))}
                </CardContainerList>
                <div>
                <button
                    className="text-secondary font-weight-bold"
                    ref={loadMoreRef}
                    onClick={() => {setPageNumber(pageNumber+1); fetchMore(pageNumber);}}
                    disabled={!canFetchMore || isFetchingMore}
                >
                    {isFetchingMore
                    ? 'Cargando...'
                    : canFetchMore
                    ? <MoreIcon/>
                    : ''}
                </button>
                {
                // pagina {pageNumber}
                }
                </div>
            </>
            )}
            </SectionContainerStyled>    
            

            <MiniFooter />
            <CookieBanner/>
            {debug && 
                <ReactQueryDevtools initialIsOpen />
            }
        </LayoutContent>
    );
}

export default BusinessList;

